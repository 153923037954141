'use client'
import ProfileCardRegister from '@/app/components/navigation/ProfileCardRegister'
import StatusPlatform from '@/app/components/navigation/StatusPlatform'
import MerchantDropdown from '@/app/components/navigation/MerchantDropdown'
import { MerchantType, ProfileType } from '@/app/types/collection'
import {
  NavbarContent,
  NavbarMenu,
  NavbarMenuToggle,
  Navbar as NextUiNavbar,
} from '@nextui-org/navbar'
import { useState } from 'react'
import MenuLinks from '@/app/components/navigation/MenuLinks/MenuLinks'
import { HeaderMerchantProfile } from '@/app/types/collection'

export default function TopNavbarRegister({
  merchantLogo,
}: {
  merchantLogo: React.JSX.Element
}) {
  const [isMenuOpen, setIsMenuOpen] = useState(false)

  const closeMenu = () => {
    setIsMenuOpen(false)
  }

  return (
    <NextUiNavbar
      maxWidth={'full'}
      className={'text-base bg-white'}
      classNames={{ wrapper: 'px-4' }}
      isBordered
      onMenuOpenChange={setIsMenuOpen}
      isMenuOpen={isMenuOpen}
    >
      <div className={'w-40 md:w-44 h-full'}>
        <div className='relative w-full h-full content-center'>
          {merchantLogo}
        </div>
      </div>
      <NavbarContent
        className="hidden lg:flex flex-row ms-auto grow content-center"
        justify={'start'}
      >
        <div className='text-3xl text-black font-thin'>Admin</div>
      </NavbarContent>
      <NavbarContent
        className="hidden lg:flex flex-row ms-auto grow gap-5"
        justify={'end'}
      >
        <ProfileCardRegister />
      </NavbarContent>

    </NextUiNavbar>
  )
}
